<template>
  <div class="mt20">
    <div class="top">
      <div class="title flex-center">
        课程订单
        <helpIcon style="margin-left:6px;" prompt="统计网校累计已支付的订单数量，包括已支付、已作废和已过期的订单(不包括未支付的订单)"></helpIcon>
      </div>
      <div class="number">
        {{ orderNum.course_order_num }}
        <span style="font-size:12px;">笔</span>
      </div>
      <div class="flex-center contianer">
        <div class="left">
          <div class="pie-contain">
            <pieChart :pieData="list1" :options="pieOption" title="直播课订单"></pieChart>
          </div>
          <div class="pie-contain">
            <pieChart :pieData="list2" :options="pieOption" title="录播课订单"></pieChart>
          </div>
        </div>
        <div class="center">
          <div class="pie-contain positionCenterPie">
            <pieChart :pieData="list5" :options="positionCenterPieOption"></pieChart>
            <helpIcon class="centerPie-help" prompt="统计网校不同课程类型支付成功的订单占比，包括已支付、已作废和已过期的订单(不包括未支付的订单)"></helpIcon>
          </div>
        </div>
        <div class="right">
          <div class="pie-contain">
            <pieChart :pieData="list3" :options="pieOption" title="小班课订单"></pieChart>
          </div>
          <div class="pie-contain">
            <pieChart :pieData="list4" :options="pieOption" title="系列课订单"></pieChart>
          </div>
        </div>
      </div>
    </div>
    <!--课程总收入  和  今日收入-->
    <div class="flex-center">
      <div class="contain1">
        <div class="flex-center title">
          网校总收入
          <helpIcon
            style="margin-left:6px;"
            prompt="统计网校累计的收入金额，按本月、上月统计，包括课程销售额、营销活动和直播打赏送礼的收益(图表中的直播课不包括打赏送礼收入)"
          ></helpIcon>
        </div>
        <div class="flex-center course-detail">
          <div class="flex-center item">
            <i class="number">{{ amount.totalProfit }}</i
            >元
          </div>
        </div>
        <div class="vt treeChart">
          <treeChart :float="true" :lists="list6" class="tree-caht"></treeChart>
        </div>
      </div>
      <div class="contain1">
        <div class="flex-center title">
          今日收入
          <helpIcon
            style="margin-left:6px;"
            prompt="统计网校今日的收入金额，包括课程销售额、营销活动和直播打赏送礼的收益(图表中的直播课不包括打赏送礼收入)"
          ></helpIcon>
        </div>
        <div class="flex-center course-detail">
          <div class="flex-center item">
            <i class="number">{{ amount.todayProfit }}</i
            >元
          </div>
        </div>
        <div class="vt treeChart">
          <treeChart :float="true" :alone="true" :lists="list7" class="tree-caht"></treeChart>
        </div>
      </div>
    </div>
    <!--收入增长趋势（元）-->
    <div class="mt20 studentGrowthChart">
      <div class="flex-center header">
        <div class="flex-center title">
          收入增长趋势（元）
          <helpIcon style="margin-left:6px;" prompt="统计网校课程销售额的收入增长趋势(不包括直播打赏送礼收益)"></helpIcon>
        </div>
        <selectTimeInterval
          class="selectTim"
          v-model="post.area_type"
          @input="input"
          :option="[
            { name: '最近7天', id: 1 },
            { name: '最近30天', id: 2 }
          ]"
        ></selectTimeInterval>
        <el-date-picker
          :value="post.time"
          @input="changeTime"
          type="daterange"
          align="right"
          unlink-panels
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :pickerOptions="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="line-chart">
        <lineChart :options="saleIncrementData"></lineChart>
      </div>
    </div>
    <!--订单金额分布-->
    <div class="mt20 studentGrowthChart">
      <div class="flex-center header">
        <div class="title">订单金额数量分布</div>
      </div>
      <div class="treeChart">
        <treeChart :float="true" :alone="true" :lists="orderAmountAreaData"></treeChart>
      </div>
    </div>
  </div>
</template>
<script>
import helpIcon from '@/components/helpIcon'
import pieChart from '@/components/dataAnalysis/pieChart'
import lineChart from '@/components/dataAnalysis/line'
import treeChart from '@/components/dataAnalysis/tree'
import selectTimeInterval from './selectTimeInterval'

export default {
  name: 'orderAnalysis',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 86400000
        }
      },

      pieOption: {
        title: {
          right: 120,
          left: 'auto',
          top: 8,
          textStyle: {
            fontSize: 13,
            color: '#333'
          }
        },
        legend: {
          right: 38,
          top: 'center',
          left: 'auto',
          width: 180,
          itemGap: 14
        },
        series: [{ right: 218, bottom: 'auto' }]
      },

      positionCenterPieOption: {
        title: {
          text: '课程类型订单占比',
          top: 0,
          left: 'center',
          textStyle: {
            fontSize: 13,
            color: '#333'
          }
        },
        legend: {
          width: 220,
          itemGap: 20
        },
        series: [{ top: 20, bottom: 60 }]
      },

      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      list6: [],
      list7: [],

      // list1: [
      //   {
      //     value: 1,
      //     name: "课程收入",
      //     itemStyle: { color: "#0098FF" }
      //   },
      //   {
      //     value: 22,
      //     name: "直播打赏",
      //     itemStyle: { color: "#F8B832" }
      //   },
      //   {
      //     value: 1,
      //     name: "录播收入",
      //     itemStyle: { color: "#0098FF" }
      //   },
      //   {
      //     value: 22,
      //     name: "小班打赏",
      //     itemStyle: { color: "#F8B832" }
      //   }
      // ],

      // list2: [
      //   ["product", "本月", "上月"],
      //   ["拼团", 1, 2],
      //   ["裂变海报", 1, 2],
      //   ["优惠券", 1, 2],
      //   ["兑换码", 1, 2]
      // ],

      amount: {
        // totalProfit：课程总收入
        // todayProfit：今日收入
      },
      orderNum: {
        // course_order_num：订单总数
        // payed_large_course_order_num：已支付直播课订单
        // unpayed_large_course_order_num：未支付直播课订单
        // overdue_large_course_order_num：已过期直播课订单
        // invalid_large_course_order_num：已作废直播课订单
        // payed_private_course_order_num：已支付小班课订单
        // unpayed_private_course_order_num：未支付小班课订单
        // overdue_private_course_order_num：已过期小班课订单
        // invalid_private_course_order_num：已作废小班课订单
        // payed_record_course_order_num：已支付录播课订单
        // unpayed_record_course_order_num：未支付录播课订单
        // overdue_record_course_order_num：已过期录播课订单
        // invalid_record_course_order_num：已作废录播课订单
        // payed_series_course_order_num：已支付系列课订单
        // unpayed_series_course_order_num：未支付系列课订单
        // overdue_series_course_order_num：已过期系列课订单
        // invalid_series_course_order_num：已作废系列课订单
      },
      theMonth: {
        // theMonth_large_course_order_amount：本月直播课收入
        // theMonth_private_course_order_amount：本月小班课收入
        // theMonth_record_course_order_amount：本月录播课收入
        // theMonth_series_course_order_amount：本月系列课收入
        // theMonth_gift_order_amount：本月直播打赏收入
        // theMonth_sale_order_amount：本月营销收入
      },
      lastMonth: {
        // lastMonth_large_course_order_amount：上月直播课收入
        // lastMonth_private_course_order_amount：上月小班课收入
        // lastMonth_record_course_order_amount：上月录播课收入
        // lastMonth_series_course_order_amount：上月系列课收入
        // lastMonth_gift_order_amount：上月直播打赏收入
        // lastMonth_sale_order_amount：上月营销收入
      },
      today: {
        // today_large_course_order_amount：今日直播课收入
        // today_private_course_order_amount：今日小班课收入
        // today_record_course_order_amount：今日录播课收入
        // today_series_course_order_amount：今日系列课收入
        // today_gift_order_amount：今日直播打赏收入
        // today_sale_order_amount：今日营销收入
      },

      // 收入增长趋势（元）
      post: {
        startTime: '',
        endTime: '',
        time: '',
        area_type: 1
      },

      saleIncrementData: {},

      // 金额分布
      orderAmountAreaData: []
    }
  },

  watch: {
    post: {
      handler() {
        // 获取营销增长趋势数据
        this.getOrderAmountIncrement()
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.getorderBaseData()
    this.getOrderAmountAre()
  },

  methods: {
    input() {
      this.post.startTime = ''
      this.post.endTime = ''
      this.post.time = ''
    },

    // 页面基础数据
    getorderBaseData() {
      this.$http({
        url: '/statistics/orderBaseData',
        callback: ({ data: { amount, lastMonth, orderNum, theMonth, today } }) => {
          this.amount = amount
          this.lastMonth = lastMonth
          this.orderNum = orderNum
          this.theMonth = theMonth
          this.today = today

          this.list1 = [
            {
              value: orderNum.payed_large_course_order_num,
              name: '已支付',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: orderNum.unpayed_large_course_order_num,
              name: '未支付',
              itemStyle: { color: '#F8B832' }
            },
            {
              value: orderNum.invalid_large_course_order_num,
              name: '已作废',
              itemStyle: { color: '#66B358' }
            },
            {
              value: orderNum.overdue_large_course_order_num,
              name: '已过期',
              itemStyle: { color: '#FA6400' }
            }
          ]
          this.list2 = [
            {
              value: orderNum.payed_record_course_order_num,
              name: '已支付',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: orderNum.unpayed_record_course_order_num,
              name: '未支付',
              itemStyle: { color: '#F8B832' }
            },
            {
              value: orderNum.invalid_record_course_order_num,
              name: '已作废',
              itemStyle: { color: '#66B358' }
            },
            {
              value: orderNum.overdue_record_course_order_num,
              name: '已过期',
              itemStyle: { color: '#FA6400' }
            }
          ]
          this.list3 = [
            {
              value: orderNum.payed_private_course_order_num,
              name: '已支付',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: orderNum.unpayed_private_course_order_num,
              name: '未支付',
              itemStyle: { color: '#F8B832' }
            },
            {
              value: orderNum.invalid_private_course_order_num,
              name: '已作废',
              itemStyle: { color: '#66B358' }
            },
            {
              value: orderNum.overdue_private_course_order_num,
              name: '已过期',
              itemStyle: { color: '#FA6400' }
            }
          ]
          this.list4 = [
            {
              value: orderNum.payed_series_course_order_num,
              name: '已支付',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: orderNum.unpayed_series_course_order_num,
              name: '未支付',
              itemStyle: { color: '#F8B832' }
            },
            {
              value: orderNum.invalid_series_course_order_num,
              name: '已作废',
              itemStyle: { color: '#66B358' }
            },
            {
              value: orderNum.overdue_series_course_order_num,
              name: '已过期',
              itemStyle: { color: '#FA6400' }
            }
          ]
          this.list5 = [
            {
              value: orderNum.large_course_order_num,
              name: '直播课',
              itemStyle: { color: '#0093F8' }
            },
            {
              value: orderNum.record_course_order_num,
              name: '录播课',
              itemStyle: { color: '#F8B832' }
            },
            {
              value: orderNum.private_course_order_num,
              name: '小班课',
              itemStyle: { color: '#66B358' }
            },
            {
              value: orderNum.series_course_order_num,
              name: '系列课',
              itemStyle: { color: '#FA6400' }
            }
          ]
          // 课程总收入
          this.list6 = [
            ['product', '本月', '上月'],
            ['直播课', theMonth.theMonth_large_course_order_amount, lastMonth.lastMonth_large_course_order_amount],
            ['录播课', theMonth.theMonth_record_course_order_amount, lastMonth.lastMonth_record_course_order_amount],
            ['小班课', theMonth.theMonth_private_course_order_amount, lastMonth.lastMonth_private_course_order_amount],
            ['系列课', theMonth.theMonth_series_course_order_amount, lastMonth.lastMonth_series_course_order_amount],
            ['打赏送礼', theMonth.theMonth_gift_order_amount, lastMonth.lastMonth_gift_order_amount]
            // ['营销活动', theMonth.theMonth_sale_order_amount, lastMonth.lastMonth_sale_order_amount]
          ]
          // 今日收入
          this.list7 = [
            ['直播课', today.today_large_course_order_amount],
            ['录播课', today.today_record_course_order_amount],
            ['小班课', today.today_private_course_order_amount],
            ['系列课', today.today_series_course_order_amount],
            ['打赏送礼', today.today_gift_order_amount]
            // ['营销活动', today.today_sale_order_amount]
          ]
        }
      })
    },

    // 收入增长趋势（元）
    getOrderAmountIncrement() {
      this.$http({
        url: '/statistics/orderAmountIncrement',
        data: this.post,
        callback: ({ data: { profitIncrement } }) => {
          this.saleIncrementData = {
            legend: {
              left: 0,
              data: ['直播课', '录播课', '小班课', '系列课']
            },
            xAxis: {
              data: profitIncrement.map(item => item[0])
            },
            yAxis: {
              minInterval: null
            },
            series: [
              {
                name: '直播课',
                type: 'line',
                data: profitIncrement.map(item => item[1]),
                lineStyle: {
                  color: '#00D966'
                },
                itemStyle: {
                  color: '#00D966',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '录播课',
                type: 'line',
                data: profitIncrement.map(item => item[3]),
                lineStyle: {
                  color: '#0098FF'
                },
                itemStyle: {
                  color: '#0098FF',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '小班课',
                type: 'line',
                data: profitIncrement.map(item => item[2]),
                lineStyle: {
                  color: '#F8B832'
                },
                itemStyle: {
                  color: '#F8B832',
                  borderWidth: 4
                },
                smooth: true
              },
              {
                name: '系列课',
                type: 'line',
                data: profitIncrement.map(item => item[4]),
                lineStyle: {
                  color: '#B675F0'
                },
                itemStyle: {
                  color: '#B675F0',
                  borderWidth: 4
                },
                smooth: true
              }
            ]
          }
        }
      })
    },

    // 订单金额分布
    getOrderAmountAre() {
      this.$http({
        url: '/statistics/orderAmountArea',
        callback: ({ data }) => {
          this.orderAmountAreaData = [
            ['product'],
            ['0-50（元）', data[0]],
            ['50-100（元）', data[1]],
            ['100-200（元）', data[2]],
            ['200-500（元）', data[3]],
            ['500-1000（元）', data[4]],
            ['1000-1500（元）', data[5]],
            ['大于1500（元）', data[6]]
          ]
          // this.orderAmountAreaData = {
          //   legend: {
          //     left: 0
          //   },
          //   xAxis: {
          //     data: ['0-50（元）', '50-100（元）', '100-200（元）', '200-500（元）', '500-1000（元）', '1000-1500（元）', '大于1500（元）']
          //   },
          //   yAxis: {
          //     minInterval: 0
          //   },
          //   series: {
          //     type: 'line',
          //     name: '订单数量',
          //     data: data,
          //     lineStyle: {
          //       color: '#0093F8'
          //     },
          //     itemStyle: {
          //       color: '#0093F8'
          //     }
          //   }
          // }
        }
      })
    },

    changeTime(value) {
      this.post = Object.assign({}, this.post, {
        startTime: value ? value[0] / 1000 : '',
        endTime: value ? value[1] / 1000 : '',
        time: value || '',
        area_type: value ? 3 : 1
      })
    }
  },

  components: {
    pieChart,
    lineChart,
    helpIcon,
    treeChart,
    selectTimeInterval
  }
}
</script>
<style lang="scss" scoped>
i {
  color: gray;
}
.top {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 60px;
  background-color: #fff;
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    color: rgba(51, 51, 51, 1);
  }
  .number {
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 31px;
  }
  .contianer {
    display: flex;
    white-space: nowrap;
    .left,
    .center,
    .right {
      flex: 1;
      min-width: 240px;
    }
    .pie-contain {
      height: 120px;
      margin-bottom: 20px;
      position: relative;
      &.positionCenterPie {
        height: 260px;
        margin-bottom: 0;
        margin-top: -50px;
        position: relative;
        .centerPie-help {
          position: absolute;
          left: 50%;
          top: 3px;
          transform: translateX(-50%);
          margin-left: 62px;
        }
      }
    }
  }
}
.proportionChart,
.studentGrowthChart {
  padding: 20px;
  background: #fff;
  .title {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
  .pie-contain {
    height: 228px;
    position: relative;
  }
  .char-menu {
    width: 316px;
    height: 30px;
    margin: 20px auto 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(229, 229, 229, 1);
  }
}
.studentGrowthChart {
  padding-bottom: 30px;
  .header {
    margin-bottom: 12px;
    .selectTim {
      width: 158px;
      margin-right: 20px;
    }
    ::v-deep .el-input,
    ::v-deep .el-range-input {
      font-size: 12px;
      line-height: 30px;
    }
    ::v-deep .el-range-separator,
    ::v-deep .el-date-editor .el-range__icon,
    ::v-deep .el-date-editor .el-range__close-icon {
      line-height: 25px;
    }
    ::v-deep .el-form-item__content {
      height: 30px;
    }
    ::v-deep .el-input__inner,
    ::v-deep .el-input {
      width: 120px;
    }
    ::v-deep .el-date-editor {
      width: 220px;
      height: 30px;
    }
    ::v-deep .el-form-item {
      margin: 0;
    }
  }
}
.line-chart {
  height: 305px;
  position: relative;
}
.contain1 {
  flex: 1;
  padding: 0 0 22px;
  background: #fff;
  white-space: nowrap;
  &:first-child {
    padding-left: 20px;
  }
}
.title {
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  color: rgba(51, 51, 51, 1);
}
.course-detail {
  .item {
    font-size: 12px;
    margin-right: 40px;
    position: relative;
    align-items: baseline;
    color: rgba(51, 51, 51, 1);
    > i {
      margin: 0 5px 0 0;
      font-size: 24px;
      font-weight: bold;
      line-height: 31px;
      position: relative;
      top: 2px;
      color: rgba(51, 51, 51, 1);
    }
    .helpIcon {
      position: absolute;
      top: -4px;
      right: -11px;
    }
  }
}
.pieChart,
.treeChart {
  font-size: 0;
  position: relative;
}
.pieChart {
  width: 516px;
  height: 206px;
  margin-top: 30px;
}
.space-bd {
  width: 1px;
  height: 266px;
  margin: 0 120px 0 60px;
  background: rgba(216, 216, 216, 1);
}
.treeChart {
  width: 100%;
  height: 306px;
}
</style>
